<template>
  <div>
    <div class="banner-wrap-img ">
      <img src="./../../assets/images/plan-banner.jpg">
    </div>
    <div class="member-bannerimg">
    </div>
    <div class="container main-block">
      <div class="results-items"  :class="{'search-filter': !tempId}">
        <div v-if="!tempId">
          <h5 class="mb-4 ml-2">Please fill filter for association benefit</h5>
          <association-search-edit></association-search-edit>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-md-4 col-xl-2">
              <h5>Membership includes Entire Family</h5>
            </div>
            <div class="col-md-4 col-xl-2 txt-results-item">
              <div class="txt-results-item__name" style="padding: 0 2px 20px 2px">
                Elevate Wellness Bronze Membership
              </div>
              <div class="txt-results-item__price">$5.00 / Mo.</div>

              <div class="txt-results-item__btn">
                <button @click="addCart('eyJpdiI6Ino1VDBQaWQyYWt6akRvRDBiaWUxSFE9PSIsInZhbHVlIjoiWG9qWmVTb0s4aXJOOERCUGxIT2hMdz09IiwibWFjIjoiOTM3N2EwMzdkMTU1OWE4NWQ2ODI5MTdlNGJmYTZkODJlZmJjMmNmN2M0YzQ4NTAzMWFhNDY0MWEyMjQ2MWNkZiJ9','eyJpdiI6IjV2aWhFTUFzaGdsZDIxYmFZKzQrMFE9PSIsInZhbHVlIjoiMlU0eW1IamFIaS9oUHplUGpUdDRWdz09IiwibWFjIjoiYzc4YmRkMjkyYjQ4ZDdjOTVmNzk2MWMzYjQ4ODZlMjhiZmE3ZjEzMTJjNWExNzUzYmIzYzgwMmYxODBmOTYyNSJ9')"
                        class="btn btn-success text-uppercase"
                >
                  ADD CART
                </button>
              </div>
            </div>
            <div class="col-md-4 col-xl-2 txt-results-item">
              <div class="txt-results-item__name" style="padding: 0 2px 20px 2px">
                Elevate Wellness Silver Membership
              </div>
              <div class="txt-results-item__price">$12.00 / Mo.</div>

              <div class="txt-results-item__btn">
                <button  @click="addCart('eyJpdiI6IjAya0NjRnJ0a0ErRjdFajM5WDE1ZkE9PSIsInZhbHVlIjoiRDQzTEt2cE5qV3F4cytkTk9WVzRCQT09IiwibWFjIjoiNjZkMDMwZTZjYTZiOTBhYzcyYTc2NGJlYWQ3ZTIxMTU5MmY4NGQ5NjE4ZGM3MTJmZGU0NjVjZTljMTQxNWU1ZiJ9','eyJpdiI6IlpxK2RUUnNGU3dqNUJXbC9sSmpoSlE9PSIsInZhbHVlIjoiWUx5R1g1SWpxTmVKOTd3eG9Ld1g4UT09IiwibWFjIjoiOWE3M2RhNGYwNzg3YzRiMjllNDRhODg4MTA0MDM0MDM2OTNmMDAxOTk0NmQ2ZjcyYjc3MDcwNzliMzY4Njc0MiJ9')"
                         class="btn btn-success text-uppercase"

                >
                  ADD CART
                </button>
              </div>
            </div>
            <div class="col-md-4 col-xl-2 txt-results-item">
              <div class="txt-results-item__name" style="padding: 0 2px 20px 2px">
                Elevate Wellness Gold Membership
              </div>
              <div class="txt-results-item__price">$16.00 / Mo.</div>

              <div class="txt-results-item__btn">
                <button @click="addCart('eyJpdiI6InI4RkVqVnVxZHFHdVNFSWhkT0t6WkE9PSIsInZhbHVlIjoiVGNaY2Q4YUo5L3NhdjY0N3lJTUlhZz09IiwibWFjIjoiZGNkOGQ3YmU2M2I2ZDE4Nzk1N2I4ZTFmYTBhY2ZkMWY1YmE5OGEzNDIyOTU2MDBiMWZmZjAxYzQ3ZWRmYTE4ZiJ9','eyJpdiI6IkxYZm9mVVVJc085V1pXN0NWOXdhWWc9PSIsInZhbHVlIjoiU2RveTRtM3FkcUljNlFoVXRCN1A5dz09IiwibWFjIjoiNWMwNmQ5M2JkOGEzMGVjZTFmZWZlMjg0OGEzZjQyMmExYzQ4NGE3ZDgzOWIxMTdmYTFlZWJhMWUxNDdmMzdiOCJ9')"
                        class="btn btn-success text-uppercase"

                >
                  ADD CART
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <new-member-ship />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssociationSearchEdit from "../AssociationSearchEdit";
import axios from "axios";
import NewMembership from "../../views/NewMembership"
export default {
  name: "NewPlan_Details",
  components: {
    AssociationSearchEdit,
    'new-member-ship': NewMembership
  },
  data: function () {
    return {
      goEnroll1234LandingImage: 'https://images.unsplash.com/photo-1475503572774-15a45e5d60b9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
      tempId: null
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
  },
  methods: {
    addCart: function (pid, ppid) {
      let app = this;
      axios.post(process.env.VUE_APP_API_BASE + '/add-plan-to-cart', {
        enrollment_id: app.tempId,
        plan_id: pid,
        plan_pricing_id: ppid,
      })
          .then(function (response) {
            console.log(response);
            if (response.data.status == 'success') {
              app.$router.push('/cart')
            }
          })
          .catch(function (error) {
            console.log(error.response);
          });
    },
  },
  listener: {
    renderTempId () {
      let app = this;
      app.tempId = window.localStorage.getItem('tempId');
    }
  }
}
</script>

<style scoped>
.results-items {
  background: #fdfdfd;
  border: 1px solid #e1e1e1;
  padding: 4rem 2rem;
  margin-top: 5rem;
  border-radius: 6px;
  position: relative;
  top: -150px;
}
.results-items h5 {
  font-weight: 800;
  color: #4f4f4f !important;
}
.txt-results-item {
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  color: #4f4f4f;
  font-weight: 800 !important;
  margin-bottom: 40px;
  border-left: 1px solid #e1e1e1;
}
.btn-success{
  margin-top: 20px;
}
.txt-results-item__name{
  color: #2C5F97;
}
.banner-wrap-img{
  height: 325px;
}
.banner-wrap-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-filter {
  padding: 4rem 1rem !important;
}
</style>
