<template>
  <div class="banner-text">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <p>Access the Power of Group Buying! By joining Elevate Wellness’ network of thousands of small businesses owners, employees and self-employed (1099) workers, you can access quality benefits at competitive rates not otherwise available to you.</p>
            <router-link to="" v-b-modal.videoPop>Watch our video to learn more about Elevate and how to choose a plan that’s right for you!</router-link>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="videoPop" centered size="lg"  v-model="show">
      <template #modal-header>
        <div class="popUp-header">
          <b-button variant="close-btn" @click="show=false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="videoPlay">
          <video controls autoplay width="100%" height="350" title="Goenroll123" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              <source src="https://uploaded-files-dashboard.s3.us-east-2.amazonaws.com/videos/1667237094_Goenroll123_How_to_Enroll_V1.mp4" type="video/mp4" >
              Your browser does not support the video tag.
          </video>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
export default {
  name:'BannerText',
  data() {
    return {
      show: false,
    }
  }
}
</script>
